import { addMonths, format, getMonth } from "date-fns";
import Text from "../Text";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { useDateFnsLocale, useTranslation } from "@/lib/i18n/client";
import { MAXIMUM_NUMBER_OF_STAY_MONTHS, MINIMUM_NUMBER_OF_STAY_DAYS } from "@/lib/constants/datepicker";

type Month = {
    name: string;
    d: Date;
};

const generateNextMonths = (selectedDate: Date, nrOfMonths: number) => {
    let months: Month[] = [];
    const month = getMonth(selectedDate);
    if (month < 0 || month > 11) {
        console.error("Invalid month passed!", month);
    }

    // TODO: revamp, bcs this acts odd based on day of month! ie end of june was ok but start of july doesnt show august (starts from Sept) in Check-Out date!
    for (let monthsAhead = 0; monthsAhead <= nrOfMonths - 1; monthsAhead++) {
        const newMonth = addMonths(selectedDate, monthsAhead);
        months = [
            ...months,
            {
                d: newMonth,
                name: format(newMonth, "LLLL"),
            },
        ];
    }

    return months;
};

type Props = {
    selectedDate: Date;
    onMonthSelect: (m: Date) => void;
};

const MonthPicker = ({ selectedDate, onMonthSelect }: Props) => {
    const nrOfMonthsAhead = 6;
    const months = generateNextMonths(selectedDate, nrOfMonthsAhead);
    const lang = useLanguage();
    const { t } = useTranslation(lang, "search");
    useDateFnsLocale();

    return (
        <div className="p-3 flex flex-col w-full">
            <div className=" absolute -top-1.5 lg:left-2/4 left-3/4 bg-white h-3 w-3 rotate-45 border border-cyan-500 lg:border-gray-300 border-b-0 border-r-0 "></div>
            <Text as="p" className=" font-extrabold py-1">
                {t("datepicker.select-your-checkout-month")}
            </Text>
            <div className="grid grid-cols-3 gap-4 p-4">
                {months.map((m) => {
                    return (
                        <button
                            className="bg-gray-300 bg-opacity-25 py-2 rounded-md"
                            key={m.name}
                            onClick={() => onMonthSelect(m.d)}
                        >
                            {m.name}
                        </button>
                    );
                })}
            </div>
            <div className="flex items-center justify-center text-xs gap-4 text-primary-500 font-bold">
                <Text as="span">Min stay: {MINIMUM_NUMBER_OF_STAY_DAYS} days</Text>|
                <Text as="span">Max stay: {MAXIMUM_NUMBER_OF_STAY_MONTHS} months</Text>
            </div>
        </div>
    );
};

export default MonthPicker;