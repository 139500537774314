import { DATE_AS_STR_FORMAT } from "@/lib/constants/datepicker";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import { classNames } from "@/lib/utils/general";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    format,
    eachDayOfInterval,
    isBefore,
    isSameDay,
    startOfMonth,
    endOfMonth,
    setDay,
    getDay,
    subDays,
    isAfter,
} from "date-fns";

type CalendarMonthProps = {
    allowDatesAfter?: Date;
    allowDatesBefore?: Date;
    moveToPreviousMonth?: () => void;
    moveToNextMonth?: () => void;
    calendarMonth: Date | number;
    selectedDate?: Date;
    isDouble?: boolean;
    isLeft?: boolean;
    arrowDirection?: string;
    onSelect: (d: Date) => void;
    unavailableDays?: Date[];
    allowPast?: boolean;
};

type DateItem = {
    d: Date;
    date: string;
    isInPast: boolean;
    display?: boolean;
    isAllowed: boolean;
    isSelected: boolean;
};

const getDayNames = () => {
    const getDayName = (dayOfWeek: number) => {
        return format(setDay(Date.now(), dayOfWeek), "EEEEEE");
    };

    return {
        monday: getDayName(1),
        tuesday: getDayName(2),
        wednesday: getDayName(3),
        thursday: getDayName(4),
        friday: getDayName(5),
        saturday: getDayName(6),
        sunday: getDayName(0),
    };
};

const generateDatesBetween = (
    calendarMonth: Date | number,
    selectedDate?: Date,
    allowDatesAfter?: Date,
    allowDatesBefore?: Date,
    unavailableDays: Date[] = [],
    allowPast: boolean = false,
): DateItem[] => {
    const calendarStart = startOfMonth(calendarMonth);
    const calendarEnd = endOfMonth(calendarMonth);
    const calendarInterval = {
        start: calendarStart,
        end: calendarEnd,
    };

    const result = eachDayOfInterval(calendarInterval);
    const unavailableDaysAsStr = unavailableDays.map(d => format(d, DATE_AS_STR_FORMAT));

    const res = result.map((d) => {
        const currentDate = new Date();
        const isInPast = allowPast ? false : isBefore(d, currentDate) && !isSameDay(d, currentDate);
        const isAfterDate = allowDatesAfter ? (isAfter(d, allowDatesAfter) || isSameDay(d, allowDatesAfter)) : true;
        const isBeforeDate = allowDatesBefore ? isBefore(d, allowDatesBefore) : true;
        const isAvailable = !unavailableDaysAsStr.includes(format(d, DATE_AS_STR_FORMAT));

        return {
            d: d,
            date: format(d, DATE_AS_STR_FORMAT),
            isInPast: isInPast,
            display: true,
            isSelected: selectedDate && isSameDay(d, selectedDate),
            isAllowed: isAfterDate && isBeforeDate && isAvailable,
        } as DateItem;
    });

    return prefillUntilMonday(res);
};

const prefillUntilMonday = (dates: DateItem[]) => {
    let res = dates;
    while (getDay(res[0].d) !== 1) {
        const dayBeforeDate = subDays(res[0].d, 1);
        const dayBefore = {
            d: dayBeforeDate,
            date: format(dayBeforeDate, DATE_AS_STR_FORMAT),
            isInPast: true,
            display: false,
            isSelected: false,
            isAllowed: false,
        } as DateItem;
        res = [dayBefore, ...res];
    }
    return res;
};

const CalendarMonth = ({
    calendarMonth,
    moveToNextMonth,
    moveToPreviousMonth,
    onSelect,
    isDouble,
    isLeft,
    arrowDirection,
    selectedDate,
    allowDatesAfter,
    allowDatesBefore,
    unavailableDays,
    allowPast = false,
}: CalendarMonthProps) => {
    const lang = useLanguage();
    const { t } = useTranslation(lang, "search");
    const dayNames = getDayNames();
    const monthName = format(calendarMonth, "LLLL");
    const year = format(calendarMonth, "yyyy");

    const days = generateDatesBetween(calendarMonth, selectedDate, allowDatesAfter, allowDatesBefore, unavailableDays, allowPast);

    const isRight = arrowDirection == "right" ? "absolute -top-1.5 right-24 md:right-1/2" : "absolute -top-1.5 left-24";

    return (
        <div className="flex-grow text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9 p-4">
            <div
                className={` ${isRight}   bg-white h-3 w-3 rotate-45 lg:invisible border border-cyan-500  lg:border-gray-300 border-b-0 border-r-0 `}
            ></div>
            <div className="flex items-center text-gray-900">
                {(!isDouble || isLeft) && (
                    <button
                        type="button"
                        className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                        onClick={moveToPreviousMonth}
                    >
                        <span className="sr-only">{t("datepicker.previous-month")}</span>
                        <FontAwesomeIcon icon={faChevronLeft} size="sm" />
                    </button>
                )}
                <div className="flex-auto text-sm font-semibold">
                    {monthName} {year}
                </div>
                {(!isDouble || !isLeft) && (
                    <button
                        type="button"
                        className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                        onClick={moveToNextMonth}
                    >
                        <span className="sr-only">{t("datepicker.next-month")}</span>
                        <FontAwesomeIcon icon={faChevronRight} size="sm" />
                    </button>
                )}
            </div>
            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                <div>{dayNames.monday}</div>
                <div>{dayNames.tuesday}</div>
                <div>{dayNames.wednesday}</div>
                <div>{dayNames.thursday}</div>
                <div>{dayNames.friday}</div>
                <div>{dayNames.saturday}</div>
                <div>{dayNames.sunday}</div>
            </div>
            <div className="isolate mt-2 grid grid-cols-7 rounded-lg text-sm">
                {days.map((day) => (
                    <button
                        key={day.date}
                        type="button"
                        className={classNames(
                            "py-1.5 focus:z-10  font-medium",
                            day.isAllowed
                                ? "hover:bg-cyan-500 hover:text-white hover:rounded-md"
                                : "hover:bg-grayAlpha-300 hover:rounded-md",
                            day.isInPast ? "text-gray-300 line-through decoration-1 hover:bg-grayAlpha-300 " : "",
                            !day.isAllowed ? "text-gray-300 line-through " : "",
                            day.isSelected && "bg-primary-500 text-white rounded-md",
                        )}
                        onClick={() => !day.isInPast && day.isAllowed && onSelect(day.d)}
                    >
                        {day.display && (
                            <time
                                dateTime={day.date}
                                className={classNames("mx-auto flex h-7 w-7 items-center justify-center rounded-full")}
                            >
                                {format(day.d, "dd")}
                            </time>
                        )}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default CalendarMonth;
