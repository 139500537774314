export type SCREEN_SIZE = "sm" | "md" | "lg" | "xl";
export const SCREENS_ORDERED: SCREEN_SIZE[] = ["sm", "md", "lg", "xl"];
export const SCREEN_BREAKPOINTS = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  };
export const HEADER_HEIGHT_PX: Record<SCREEN_SIZE, number> = {
  sm: 64,
  md: 64,
  lg: 104,
  xl: 104,
};
