import { useEffect, useState } from "react";
import { SCREEN_BREAKPOINTS, SCREEN_SIZE } from "../constants/tailwind";
import { useWindowSize } from "@uidotdev/usehooks";

const getTailwindBreakpoint = (width: number): SCREEN_SIZE => {
    if(!width){
        return "xl";
    }
    
    if (width < SCREEN_BREAKPOINTS.md) {
        return "sm";
    }

    if (width < SCREEN_BREAKPOINTS.lg) {
        return "md";
    }

    if (width < SCREEN_BREAKPOINTS.xl) {
        return "lg";
    }

    return "xl";
};

export function useTailwindBreakpoint(): [number, SCREEN_SIZE] {
    const size = useWindowSize();
    const [currentBreakpoint, setCurrentBreakpoint] = useState(getTailwindBreakpoint(size.width ?? 0));

    useEffect(() => {
        setCurrentBreakpoint(getTailwindBreakpoint(size.width ?? 0));
    }, [size]);

    return [size.width ?? 0, currentBreakpoint];

}