import { SCREEN_SIZE, SCREENS_ORDERED } from "../constants/tailwind";

export function breakpointIsLessThan(currentBreakpoint: SCREEN_SIZE, breakpoint: SCREEN_SIZE) {
    return SCREENS_ORDERED.indexOf(currentBreakpoint) < SCREENS_ORDERED.indexOf(breakpoint);
}

export function breakpointIsLessThanOrEqualTo(currentBreakpoint: SCREEN_SIZE, breakpoint: SCREEN_SIZE) {
    return SCREENS_ORDERED.indexOf(currentBreakpoint) <= SCREENS_ORDERED.indexOf(breakpoint);
}

export function breakpointIsGreaterThan(currentBreakpoint: SCREEN_SIZE, breakpoint: SCREEN_SIZE) {
    return SCREENS_ORDERED.indexOf(currentBreakpoint) > SCREENS_ORDERED.indexOf(breakpoint);
}

export function breakpointIsGreaterThanOrEqualTo(currentBreakpoint: SCREEN_SIZE, breakpoint: SCREEN_SIZE) {
    return SCREENS_ORDERED.indexOf(currentBreakpoint) >= SCREENS_ORDERED.indexOf(breakpoint);
}
